<template>
  <div class="card mt-5" :class="$style.container">
    <div class="text-dark font-size-28 mb-3">Фискализация (по умолчанию)</div>
    <a-alert
      message="Укажите данные для фискализации по умолчанию. По ним будут пробиваться фискальные чеки в случае, из POS-системы придут неверные данные (или не придут совсем)."
      type="info"
      class="mb-3"
      banner
    />
    <a-form
      :model="fiscalForm"
      :rules="rules"
      layout="vertical"
      class="mb-4"
      @finish="handleFinish"
      @finishFailed="handleFinishFailed"
    >
      <a-form-item>
        <a-checkbox v-model:checked="fiscalForm.tax">Плательщики НДС</a-checkbox>
      </a-form-item>
      <a-form-item name="spic" label="ИКПУ">
        <div class="w-100" style="position: relative">
          <a-input-search
            v-model:value="fiscalForm.spic"
            placeholder="Введите ИКПУ по умолчанию"
            @focusout="fetchSpic"
            :loading="spicLoading"
            :disabled="spicValidated"
          >
            <template #suffix>
              <a-tooltip title="ИКПУ неправильный" v-if="!spicValidated">
                <ExclamationCircleFilled style="padding: 0 5px; color: #EB2F96" />
              </a-tooltip>
              <a-tooltip title="ИКПУ правильный" v-else>
                <CheckCircleFilled style="padding: 0 5px; color: #52c41a" />
              </a-tooltip>
            </template>
          </a-input-search>
        </div>
        <a-list v-if="spicList.length" size="small" bordered :data-source="spicList">
          <template #renderItem="{ item }">
            <a-list-item @click="fiscalForm.spic = item.code;spicList = [];spicValidated = true;fetchCode(item.value)">{{ item.label }}</a-list-item>
          </template>
        </a-list>
      </a-form-item>
      <a-form-item name="code" label="Код упаковки">
        <div class="w-100" style="position: relative">
          <a-input-search
            v-model:value="fiscalForm.code"
            placeholder="Введите код упаковки по умолчанию"
            :loading="codeLoading"
            :disabled="codeValidated"
          >
            <template #suffix>
              <a-tooltip title="Код упаковки неправильный" v-if="!codeValidated">
                <ExclamationCircleFilled style="padding: 0 5px; color: #EB2F96" />
              </a-tooltip>
              <a-tooltip title="Код упаковки правильный" v-else>
                <CheckCircleFilled style="padding: 0 5px; color: #52c41a" />
              </a-tooltip>
            </template>
          </a-input-search>
        </div>
        <a-list v-if="codeList.length" size="small" bordered :data-source="codeList">
          <template #renderItem="{ item }">
            <a-list-item @click="fiscalForm.code = item.code;codeValidated = true;codeList = []">{{ item.label }}</a-list-item>
          </template>
        </a-list>
      </a-form-item>
      <a-form-item name="product" label="Название товара">
        <a-input
          v-model:value="fiscalForm.product"
          placeholder="Введите название товара по умолчанию"
        />
      </a-form-item>
      <a-form-item name="price" label="Цена товара">
        <a-input
          v-model:value="fiscalForm.price"
          placeholder="Введите цену товара по умолчанию"
        />
      </a-form-item>
      <a-form-item>
        <a-button style="width: 48%" type="default" @click="back">Назад</a-button>
        <a-button style="width: 48%; float: right" type="primary" html-type="submit" @click="() => {}">Далее</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import {
  ExclamationCircleFilled,
  CheckCircleFilled
} from "@ant-design/icons-vue";
import {reactive, ref, watch} from "vue";
import apiClient from "@/services/axios";
import {notification} from "ant-design-vue";

export default {
  name: 'FiscalView',
  components: {
    ExclamationCircleFilled,
    CheckCircleFilled
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['back', 'success'],
  setup(props, ctx) {
    const rules = {
      // spic: [
      //   {
      //     required: true,
      //     message: 'Please input spic!',
      //     trigger: 'change',
      //     min: 15,
      //     max: 18
      //   },
      // ],
      // code: [
      //   {
      //     required: true,
      //     message: 'Please input code!',
      //     trigger: 'change',
      //     min: 5,
      //     max: 8
      //   },
      // ],
      product: [
        {
          required: true,
          message: 'Please input product!',
          trigger: 'change',
        },
      ],
      price: [
        {
          required: false,
          message: 'Please input price!',
          trigger: 'change',
        },
      ],
    }
    const fiscalForm = reactive({
      tax: null,
      spic: '',
      code: '',
      product: '',
      price: ''
    })
    const spicLoading = ref(false)
    const spicValidated = ref(false)
    const codeLoading = ref(false)
    const codeValidated = ref(false)
    const spicList = ref([])
    const codeList = ref([])
    const back = () => {
      ctx.emit('back')
    }
    const handleFinish = () => {
      if (!spicValidated.value) {
        notification.warning({
          message: 'Пожалуйста выберите ИКПУ'
        })
        return;
      }
      if (!codeValidated.value) {
        notification.warning({
          message: 'Пожалуйста выберите Код упаковки'
        })
        return;
      }
      ctx.emit('success', fiscalForm)
    }
    const handleFinishFailed = () => {
      // console.log(errors)
    }
    const fetchSpic = (event) => {
      if (!event.target?.value?.length) {
        return
      }
      spicLoading.value = true
      apiClient.get(`/auth/ofd-fetch?spic=${event.target.value}`).then((res) => {
        spicList.value = res.data.data
      }).finally(() => {
        spicLoading.value = false
      })
    }
    const fetchCode = (spic) => {
      codeLoading.value = true
      apiClient.get(`/auth/ofd-fetch?spic=${spic}&type=code`).then((res) => {
        codeList.value = res.data.data
      }).finally(() => {
        codeLoading.value = false
      })
    }
    watch(props.form, form => {
      fiscalForm.tax = form.tax
      fiscalForm.spic = form.spic
      fiscalForm.code = form.code
      fiscalForm.product = form.product
      fiscalForm.price = form.price
      spicValidated.value = form.spic?.length >= 15
      codeValidated.value = form.code?.length >= 5
    })
    return {
      rules,
      codeList,
      spicList,
      fiscalForm,
      codeLoading,
      spicLoading,
      spicValidated,
      codeValidated,
      back,
      fetchSpic,
      fetchCode,
      handleFinish,
      handleFinishFailed,
    }
  }
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
<style>
.ant-list {
  max-height: 400px;
  overflow: scroll;
}
</style>

