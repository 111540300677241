<template>
  <div class="card mt-5" :class="$style.container">
    <div class="text-dark font-size-28 mb-3">Контактные данные</div>
    <a-spin v-if="certificateLoading"></a-spin>
    <div v-else>
<!--      <a-alert v-if="!certificates.length" type="error">-->
<!--        <template #message>-->
<!--          Не удалось обнаружить установленное приложение <a-typography-link href="https://e-imzo.soliq.uz/" target="_blank">E-Imzo</a-typography-link> или валидную ЭЦП. Для регистриации в системе требуется электронно-цифровая подпись организации-->
<!--        </template>-->
<!--      </a-alert>-->
      <a-form
        :model="registerForm"
        :rules="rules"
        layout="vertical"
        class="mb-4"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="phone" label="Номер телефона">
          <a-input
            :disabled="!phoneEdit"
            autocomplete="off"
            v-model:value="maskedConfirmedPhone"
            placeholder="Введите номер"
            @click="openPhoneModal"
          />
        </a-form-item>
        <a-modal
          :width="320"
          v-model:visible="phoneModal"
          title="Форма ввода номера телефона"
          @ok="submit"
          :ok-text="okText"
          :confirm-loading="loading"
        >
          <a-input
            autocomplete="off"
            ref="phoneRef"
            v-model:value="phone"
            placeholder="Номер телефона"
            @input="updatePhone($event.target.value)"
            v-maska="'+998(##) ###-##-##'"
          />
          <a-input
            v-if="otpSent"
            ref="otpRef"
            v-model:value="otp"
            class="mt-3"
            placeholder="Код подтверждение"
            v-maska="'####'"
          />
        </a-modal>
        <a-form-item name="email" label="Email">
          <a-input v-model:value="registerForm.email" placeholder="Введите адрес" />
        </a-form-item>
        <a-button type="primary" class="text-center w-100" html-type="submit">
          <strong>Далее</strong>
        </a-button>
      </a-form>
    </div>
  </div>
</template>
<script>
import {nextTick, onMounted, reactive, ref} from "vue";
import apiClient from "@/services/axios";
import {notification} from "ant-design-vue";
import EIMZO from "@/helpers/e-imzo";

export default {
  name: 'ContactsView',
  emits: ['success', 'form'],
  setup(props, ctx) {
    let eimzo
    const certificates = ref([])
    const certificateLoading = ref(false)
    onMounted(() => {
      // checkEimzo()
    })

    const checkEimzo = async () => {
      certificateLoading.value = true
      try {
        eimzo = new EIMZO
        await eimzo.install()
        await eimzo.listAllUserKeys().then(res => {
          certificates.value = res.filter(cert => cert.validTo > new Date())
        }).catch(() => {
          // console.log(err)
        }).finally(() => {
          certificateLoading.value = false
        })
      } catch (exception) {
        // console.log(exception)
        certificateLoading.value = false;
      }
    }

    const loading = ref(false)
    const okText = ref('Отправить код')
    const phoneRef = ref(null)
    const otpRef = ref(null)
    const phone = ref('')
    const otp = ref('')
    const otpSent = ref(false)
    const confirmedPhone = ref('')
    const maskedConfirmedPhone = ref('')
    const phoneEdit = ref(true)
    const phoneModal = ref(false)
    const rules = {
      phone: [
        {
          required: true,
          message: 'Please input your phone number!',
          trigger: 'change',
        },
      ],
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
          pattern: new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/)
        },
      ],
    }
    const registerForm = reactive({
      phone: '',
      email: '',
      id: null
    })
    const updatePhone = (input) => {
      let x = input
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
      phone.value = input;
      confirmedPhone.value = x[0];
    }
    const openPhoneModal = () => {
      phoneModal.value = true
      nextTick(() => {
        if (phoneRef.value) {
          phoneRef.value.focus()
        }
      })
    }

    const submit = () => {
      loading.value = true
      if (!otpSent.value) {
        apiClient.get(`/auth/otp?phone=${confirmedPhone.value}`).then(response => {
          if (response?.data?.data?.status) {
            registerForm.id = response?.data?.data?.id
            const message = response.data.data.message
            notification.success({
              message: 'Успешно',
              description: message,
            })
            okText.value = 'Подтвердить'
            otpSent.value = true
            nextTick(() => {
              if (otpRef.value) {
                otpRef.value.focus()
              }
            })
          }
        }).finally(() => {
          loading.value = false
        })
      } else {
        apiClient.get(`/auth/verify?phone=${confirmedPhone.value}&otp=${otp.value}`).then(response => {
          registerForm.email = response.data.data.email
          if (response.data.data.claim) {
            ctx.emit('form', response.data.data.claim)
          }
          if (response?.data?.data?.status) {
            const message = response.data.data.message
            notification.success({
              message: 'Успешно',
              description: message,
            })
            registerForm.id = response.data.data.id
            registerForm.phone = confirmedPhone.value
            maskedConfirmedPhone.value = phone.value
            phoneModal.value = false
            phoneEdit.value = false
          }
        }).finally(() => {
          loading.value = false
        })
      }
    }

    const handleFinish = values => {
      apiClient.get(`/auth/email?phone=${values.phone}&email=${values.email}&otp=${otp.value}`).then(response => {
        if (response?.data?.data?.status) {
          const message = response.data.data.message
          notification.success({
            message: 'Успешно',
            description: message,
          })
          ctx.emit('success', registerForm)
        }
      }).finally(() => {
        loading.value = false
      })
    }
    const handleFinishFailed = () => {
      // console.log(errors)
    }
    return {
      otp,
      phone,
      rules,
      okText,
      loading,
      otpSent,
      phoneRef,
      phoneEdit,
      phoneModal,
      certificates,
      registerForm,
      certificateLoading,
      maskedConfirmedPhone,
      submit,
      updatePhone,
      handleFinish,
      openPhoneModal,
      handleFinishFailed,
    }
  }
}
</script>
<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
